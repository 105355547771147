<template>
  <div>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center" no-gutters>
      <v-col
        :xl="cols.xl"
        :lg="cols.lg"
        :md="cols.md"
        :sm="cols.sm"
        :xs="cols.xs"
      >
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent :title="title" :backbutton="true" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <!-- <v-progress-linear indeterminate :active="loading"></v-progress-linear> -->

        <!-- l'affichage de la table -->
        <v-card flat outlined class="my-4">
          <div class="d-flex align-center">
            <div class="ml-4 mr-10 mb-4 mt-3" v-if="viewZeroElement">
              {{ messageZeroElement }}
            </div>
            <div class="ml-4 mr-10 mb-4 mt-3" v-else>
              Il y a actuellement {{ nbItems }} {{ getItemLabelText }}.
            </div>
            <v-text-field
              v-if="viewTable"
              v-model="search"
              append-icon="mdi-magnify"
              label="Rechercher"
              single-line
              class="ml-12 mr-2"
              clearable
              @click:clear="clearSearch()"
            ></v-text-field>
          </div>

          <v-row no-gutters class="my-2">
            <!-- l'ajout de fonction d'ajout -->
            <v-btn
              outlined
              color="primary"
              v-if="canAddDelete && showAddAction"
              @click="addItemFunction"
              class="ml-4 btn"
              :disabled="disableAddAction"
            >
              <div class="capitalize">{{ addItemLabel }}</div>
            </v-btn>

            <v-row no-gutters class="justify-end align-center mx-4">
              <v-tooltip bottom v-if="viewTable">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon class="mr-2 grey--text" @click="expandColumns()">{{
                      expanded ? "mdi-fullscreen-exit" : "mdi-fullscreen"
                    }}</v-icon>
                  </v-btn>
                </template>
                <span>{{
                  expanded ? "Réduire" : "Afficher toutes les colonnes"
                }}</span>
              </v-tooltip>

              <v-tooltip bottom v-if="viewTable">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    @click="onClickDisablePagination()"
                    v-on="on"
                    v-show="items.length <= 1000"
                    ><v-icon class="mr-2 grey--text">
                      {{
                        disablePagination
                          ? "mdi-format-vertical-align-center"
                          : "mdi-format-line-spacing"
                      }}</v-icon
                    >
                  </v-btn>
                </template>
                <span>
                  {{
                    disablePagination
                      ? "Activer la pagination"
                      : "Désactiver la pagination"
                  }}</span
                >
              </v-tooltip>
            </v-row>
          </v-row>

          <!-- Slot pour l'ajout de composants entre "ajouter" et la table -->
          <slot name="beforetable"></slot>

          <v-data-table
            v-if="viewTable"
            locale="fr-FR"
            :headers="headers"
            :items="elements"
            :loading="loading"
            :search="search"
            no-data-text
            no-results-text="aucun élément"
            :footer-props="footerProps"
            :options.sync="options"
            :disable-pagination="disablePagination"
            :hide-default-footer="disablePagination"
            @pagination="paginationChanged"
          >
            <template v-slot:[getItemActions()]="{ item }">
              <!-- Modification du positionnement "justify" de center à start pour que toutes les actions soit présentées sur les mêmes colonnes -->
              <div
                class="d-flex flex-grow-0 justify-start"
                v-if="!item.disableActions && viewActionColumn"
              >
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="item.view()"
                  v-if="canRead"
                >
                  mdi-eye
                </v-icon>

                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="item.edit()"
                  v-if="canEdit && !item.disabledEditAction"
                >
                  mdi-pencil
                </v-icon>

                <!-- Ajout du "!item.disableDeleteAction" pour pouvoir désactiver la suppression d'un item -->
                <v-icon
                  small
                  class="mr-2"
                  color="primary"
                  @click="onShowDeleteDialog(item)"
                  v-if="
                    canAddDelete &&
                    showDeleteAction &&
                    !item.disableDeleteAction
                  "
                >
                  mdi-close-thick
                </v-icon>
              </div>
            </template>
          </v-data-table>
        </v-card>

        <!-- Slot pour l'ajout de composants après la table -->
        <slot name="aftertable"></slot>
      </v-col>

      <DeleteTableItemDialog
        :label="deleteItemLabel"
        :item="itemToDelete"
        :visible.sync="showDeleteDialog"
        @confirmed="onDeleteConfirmed()"
      ></DeleteTableItemDialog>
    </v-row>
  </div>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DeleteTableItemDialog from "@/components/ui/DeleteTableItemDialog.vue";

import { hasRoles } from "@/service/role_service.js";

import * as logger from "@/tools/logger.js";

export default {
  name: "TableViewComponent",
  components: { Workflow, TitleAndReturnComponent, DeleteTableItemDialog },
  mixins: [WorkflowMixin],
  props: {
    /**le titre de la vue. */
    viewZeroElement: {
      type: Boolean,
      default: false,
    },
    messageZeroElement: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    /** la largeur des colonnes */
    vcols: {
      type: Object,
      default: function () {
        return {
          xl: "6",
          lg: "6",
          md: "12",
          sm: "12",
          xs: "12",
        };
      },
    },
    itemLabel: {
      type: String,
      default: "élément",
    },
    itemsLabel: {
      type: String,
      default: "éléments",
    },
    addItemLabel: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    /**les éléments */
    viewTable: {
      type: Boolean,
      default: true,
    },
    /**les éléments */
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    /** Toutes les colonnes*/
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    /**le texte affiché sur la popup de demande de suppression d'un élément */
    deleteItemLabel: {
      type: String,
      default: "Voulez-vous supprimer cet élément?",
    },
    /**activer/désactiver la pagination */
    pagination: {
      type: Boolean,
      default: false,
    },
    /**Activer/afficher la fonction d'ajout par défaut */
    showAddAction: {
      type: Boolean,
      default: true,
    },
    /**Désactiver/Activer la fonction d'ajout par défaut */
    disableAddAction: {
      type: Boolean,
      default: false,
    },
    /**Activer/afficher la fonction d'ajout par défaut */
    showDeleteAction: {
      type: Boolean,
      default: true,
    },
    /**Le rôle applicatif requis pour éditer */
    rolesForEdition: {
      type: Array,
      default: () => [],
    },
    /** Le rôle applicatif requis pour ajouter / supprimer */
    rolesForAddDelete: {
      type: Array,
      default: () => [],
    },
    /**Le rôle applicatif requis pour consulter */
    rolesForRead: {
      type: Array,
      default: () => [],
    },
    /** Tableau des étapes du worflow */
    workflowStepsSpecific: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // Rôle pour l'édition d'un item
      canEdit: false,
      // Rôle pour l'ajout/suppression d'un item
      canAddDelete: false,
      // Rôle pour la visualisation d'un item
      canRead: false,

      /**la recherche */
      search: null,

      /**les options utlisées pour la pagination et le sort */
      options: {},

      /** Les noms des colonnes à afficher. */
      columnsFilter: [],

      /**Utilisation de la pagination. */
      disablePagination: this.pagination,

      /**Les largeurs de l'écran (xl, lg, md, etc) */
      cols: JSON.parse(JSON.stringify(this.vcols)),

      /**Afficher le dialog de suppression */
      showDeleteDialog: false,

      /**l'item à supprimer */
      itemToDelete: null,

      /** les mots pour la recherche */
      words: [],

      expanded: false,

      actualItems: null,
    };
  },
  methods: {
    clearSearch() {
      logger.debug("textfield search cleared");
    },

    /**Construire la liste des colonnes par défaut.*/
    initColumnsFilter() {
      this.columnsFilter = [];

      this.columns.forEach((column) => {
        let visible = false;
        //une colonne est visible par défaut
        if (column.default === undefined) {
          visible = true;
        }

        if (column.default && column.default !== false) {
          visible = true;
        }

        // Demande de verification d'affichage de la colonne
        if (!this.viewActionColumn(column)) {
          visible = false;
        }

        if (visible) {
          this.columnsFilter.push(column.text);
        }
      });
    },

    /** Initialisation des étapes du workflow en fonction des droits de l'utilisateur.*/
    initSteps() {
      if (this.workflowStepsSpecific.length > 0) {
        this.workflowStepsSpecific.forEach((step) => {
          this.addStepForWorkflow(step);
        });
      } else {
        this.addStepForWorkflow("Choisir une table");
        if (this.canEdit) {
          this.addStepForWorkflow("Consulter/Modifier la table");
        } else {
          this.addStepForWorkflow("Consulter la table");
        }
      }
    },
    /** Appuyer sur le bouton 'ajouter qqc' */
    addItemFunction() {
      this.$emit("addItemEvent");
    },
    /**fonction pour déterminer le nom du slot du v-data-table. sinon cela provoque une erreur eslint. */
    getItemActions() {
      return `item.actions`;
    },
    /** Afficher toutes les colonnes. */
    expandColumns() {
      this.expanded = !this.expanded;

      this.columnExpanded();

      this.saveFilterTable();
    },
    /** Traitement de l'expansion de la table ou pas */
    columnExpanded() {
      if (this.expanded) {
        this.columnsFilter = this.columns.map((c) => {
          if (this.viewActionColumn(c)) {
            return c.text;
          }
        });
        this.expandFullWidth();
      } else {
        this.initColumnsFilter();
        this.expandDefaultWidth();
      }
    },

    /** Afficher le tableau sur toute la largeur de l'écran */
    expandFullWidth() {
      this.cols.lg = "12";
      this.cols.xl = "12";
      this.cols.md = "12";
      this.cols.sm = "12";
      this.cols.xs = "12";
    },
    expandDefaultWidth() {
      let widths = this.initColWidths();
      this.cols.lg = widths.lg;
      this.cols.xl = widths.xl;
      this.cols.md = widths.md;
      this.cols.sm = widths.sm;
      this.cols.xs = widths.xs;
    },

    /** Evènement de click sur la désactivation de la pagination */
    onClickDisablePagination() {
      this.disablePagination = !this.disablePagination;
      this.saveFilterTable();
    },

    onShowDeleteDialog(item) {
      this.itemToDelete = item;
      this.showDeleteDialog = true;
    },
    /**la suppression est confirmée, on appelle la méthode de l'item pour la suppression. */
    onDeleteConfirmed() {
      if (this.itemToDelete) {
        this.itemToDelete.delete();
      }
    },
    initColWidths() {
      return this.vcols;
    },

    /** Retourne faux pour la colonne actions si l'utilisateur ne peut ni read ni edit ni delete */
    viewActionColumn(column) {
      if (column.value == "actions") {
        if (!this.canEdit && !this.canAddDelete && !this.canRead) {
          return false;
        }
      }
      return true;
    },

    paginationChanged(value) {
      //console.log("pagination changed " + JSON.stringify(value, null, 4));

      if (value.itemsLength != 0) {
        this.saveFilterTable();
        this.actualItems = value.itemsLength;
      }
    },

    /** Sauvegarde les filtres de l'utilisateur */
    saveFilterTable() {
      let filter = {};
      filter.title = this.title;
      filter.search = this.search;
      filter.expanded = this.expanded;
      filter.disablePagination = this.disablePagination;
      filter.itemsPerPage = this.options.itemsPerPage;

      const parsed = JSON.stringify(filter);
      logger.debug("save filter " + parsed);
      sessionStorage.setItem("filtertable", parsed);
    },

    /** Récupère les filtres pour les appliqués */
    getFilter() {
      let filter = JSON.parse(sessionStorage.getItem("filtertable"));
      //logger.debug("getFilter --> " + JSON.stringify(filter, null, 4));

      if (filter) {
        if (this.title == filter.title) {
          this.search = filter.search;
          this.expanded = filter.expanded;
          this.disablePagination = filter.disablePagination;
          this.options.itemsPerPage = filter.itemsPerPage;

          this.columnExpanded();
        } else {
          sessionStorage.removeItem("filtertable");
        }
      }
    },
  },
  computed: {
    headers() {
      //on filtre les colonnes à afficher
      let columns = this.columns.filter((c) =>
        this.columnsFilter.find((cf) => cf == c.text)
      );

      return columns;
    },

    // Retourne le text pour le nombre d'éléments
    getItemLabelText() {
      if (this.items.length > 1) {
        return this.itemsLabel;
      } else {
        return this.itemLabel;
      }
    },

    /** Formatte le bas de la table */
    pageText() {
      if (this.actualItems) {
        let count = this.actualItems;
        let itemsPerPage = this.options.itemsPerPage;
        let from = (this.options.page - 1) * itemsPerPage;

        let p0 = from + 1;
        let p1 = parseInt(from) + itemsPerPage;
        if (p1 > count) {
          p1 = count;
        }

        return `${p0}-${p1} / ${count}`;
      } else {
        return "";
      }
    },

    footerProps() {
      return {
        itemsPerPageText: "Éléments par page",
        "items-per-page-options": [15, 30, 50],
        "page-text": this.pageText,
      };
    },

    /**Retourne le nombre d'élément dans le tableau */
    nbItems() {
      return this.items.length;
    },

    /**retourne les items à afficher : dans le cas où le collaborateur a le droit de les voir. */
    elements() {
      // Commenté car pas de cas où l'utilisateur peut accéder à la vue table mais pas voir ces éléments
      // return this.canRead ? this.items : [];
      return this.items;
    },
  },

  watch: {
    search(value) {
      logger.debug(
        "search watched " + value + " title of vue :  " + this.title
      );
      this.saveFilterTable();
    },
  },
  mounted() {
    this.canEdit = hasRoles(this.rolesForEdition);
    this.canAddDelete = hasRoles(this.rolesForAddDelete);
    this.canRead = hasRoles(this.rolesForRead);

    this.getFilter();
    this.initColumnsFilter();

    // Initialisation du worflow
    this.initSteps();
    this.goToStepForWorkflow(1);
  },
};
</script>

<style></style>
