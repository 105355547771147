var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-center", attrs: { flat: "", tile: "" } },
        [
          _c("Workflow", {
            attrs: {
              width: "600",
              height: "180",
              steps: _vm.workflowSteps,
              currentStep: _vm.workflowIndex,
              labelWidth: 200,
              lineWidth: 140,
            },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              attrs: {
                xl: _vm.cols.xl,
                lg: _vm.cols.lg,
                md: _vm.cols.md,
                sm: _vm.cols.sm,
                xs: _vm.cols.xs,
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("TitleAndReturnComponent", {
                    attrs: { title: _vm.title, backbutton: true },
                  }),
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _vm.viewZeroElement
                        ? _c("div", { staticClass: "ml-4 mr-10 mb-4 mt-3" }, [
                            _vm._v(" " + _vm._s(_vm.messageZeroElement) + " "),
                          ])
                        : _c("div", { staticClass: "ml-4 mr-10 mb-4 mt-3" }, [
                            _vm._v(
                              " Il y a actuellement " +
                                _vm._s(_vm.nbItems) +
                                " " +
                                _vm._s(_vm.getItemLabelText) +
                                ". "
                            ),
                          ]),
                      _vm.viewTable
                        ? _c("v-text-field", {
                            staticClass: "ml-12 mr-2",
                            attrs: {
                              "append-icon": "mdi-magnify",
                              label: "Rechercher",
                              "single-line": "",
                              clearable: "",
                            },
                            on: {
                              "click:clear": function ($event) {
                                return _vm.clearSearch()
                              },
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-2", attrs: { "no-gutters": "" } },
                    [
                      _vm.canAddDelete && _vm.showAddAction
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-4 btn",
                              attrs: {
                                outlined: "",
                                color: "primary",
                                disabled: _vm.disableAddAction,
                              },
                              on: { click: _vm.addItemFunction },
                            },
                            [
                              _c("div", { staticClass: "capitalize" }, [
                                _vm._v(_vm._s(_vm.addItemLabel)),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        {
                          staticClass: "justify-end align-center mx-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _vm.viewTable
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                { attrs: { icon: "" } },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "mr-2 grey--text",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.expandColumns()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.expanded
                                                          ? "mdi-fullscreen-exit"
                                                          : "mdi-fullscreen"
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    3935483258
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.expanded
                                          ? "Réduire"
                                          : "Afficher toutes les colonnes"
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm.viewTable
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.items.length <=
                                                        1000,
                                                      expression:
                                                        "items.length <= 1000",
                                                    },
                                                  ],
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onClickDisablePagination()
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass:
                                                      "mr-2 grey--text",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.disablePagination
                                                            ? "mdi-format-vertical-align-center"
                                                            : "mdi-format-line-spacing"
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    342053112
                                  ),
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.disablePagination
                                            ? "Activer la pagination"
                                            : "Désactiver la pagination"
                                        )
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._t("beforetable"),
                  _vm.viewTable
                    ? _c("v-data-table", {
                        attrs: {
                          locale: "fr-FR",
                          headers: _vm.headers,
                          items: _vm.elements,
                          loading: _vm.loading,
                          search: _vm.search,
                          "no-data-text": "",
                          "no-results-text": "aucun élément",
                          "footer-props": _vm.footerProps,
                          options: _vm.options,
                          "disable-pagination": _vm.disablePagination,
                          "hide-default-footer": _vm.disablePagination,
                        },
                        on: {
                          "update:options": function ($event) {
                            _vm.options = $event
                          },
                          pagination: _vm.paginationChanged,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: _vm.getItemActions(),
                              fn: function ({ item }) {
                                return [
                                  !item.disableActions && _vm.viewActionColumn
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex flex-grow-0 justify-start",
                                        },
                                        [
                                          _vm.canRead
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return item.view()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-eye ")]
                                              )
                                            : _vm._e(),
                                          _vm.canEdit &&
                                          !item.disabledEditAction
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return item.edit()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-pencil ")]
                                              )
                                            : _vm._e(),
                                          _vm.canAddDelete &&
                                          _vm.showDeleteAction &&
                                          !item.disableDeleteAction
                                            ? _c(
                                                "v-icon",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    small: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onShowDeleteDialog(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" mdi-close-thick ")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    : _vm._e(),
                ],
                2
              ),
              _vm._t("aftertable"),
            ],
            2
          ),
          _c("DeleteTableItemDialog", {
            attrs: {
              label: _vm.deleteItemLabel,
              item: _vm.itemToDelete,
              visible: _vm.showDeleteDialog,
            },
            on: {
              "update:visible": function ($event) {
                _vm.showDeleteDialog = $event
              },
              confirmed: function ($event) {
                return _vm.onDeleteConfirmed()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }